import TextInput from "./TextInput"
import NumberInput from "./NumberInput";
import { ReactComponent as ExitIcon } from "../../assets/svg/exit.svg";


export default function RatingInputPair(props: any) {
  return (
    <div className="flex flex-row space-x-6 text-gray-500">
      <div>
        <TextInput value={props.name} placeholder="Name" id={"rating-name-input-" + props.index} onChange={props.onChangeName}></TextInput>
      </div>
      <div>
        <NumberInput value={props.value} placeholder="Value" id={"rating-value-input-" + props.index} onChange={props.onChangeValue}></NumberInput>
      </div>
      <div>
        <ExitIcon onClick={props.onDeleteClick} className="h-10"></ExitIcon>
      </div>
    </div>
  )
}