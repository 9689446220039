import RatingInput from "../../../components/forms/RatingInputPair";
import { ReactComponent as AddIcon } from "../../../assets/svg/add.svg";
import { availableRatingsSignal } from "../../../signals/ratingSignals";

//Create model file for Rating type
type Rating = { name: string; value: number };
//toDo: import types from repository
const RatingConfiguration = () => {
  function handleAddEmptyRating(): void {
    availableRatingsSignal.value = [...availableRatingsSignal.value, { name: "", value: 0 }];
  }

  function handleModifyRating(index: number, name: string, value: number): void {
    let newRatings = [...availableRatingsSignal.value]
    newRatings[index] = { name: name, value: Number(value) }
    availableRatingsSignal.value = newRatings;
  }

  function handleDeleteRating(index: number): void {
    availableRatingsSignal.value = availableRatingsSignal.value.filter(
      (_, i) => i !== index
    );
  }

  return (
    <div>
      <div className="space-y-6 flex flex-col justify-center items-center">
        {availableRatingsSignal.value.map((pair: Rating, index: number) => (
          <div key={index}>
            <RatingInput
              key={index + "-RatingInput"}
              index={index}
              name={pair.name}
              value={JSON.stringify(pair.value)}
              onChangeName={(event: any) =>
                handleModifyRating(index, event.target.value, pair.value)
              }
              onChangeValue={(event: any) =>
                handleModifyRating(index, pair.name, event.target.value)
              }
              onDeleteClick={() => handleDeleteRating(index)}
            ></RatingInput>
          </div>
        ))}

        <AddIcon
          className="text-gray-400 h-12"
          onClick={handleAddEmptyRating}
        ></AddIcon>
      </div>
    </div>
  );
};

export default RatingConfiguration;
