import { signal, Signal } from "@preact/signals-react";

type Tag = { id: string; name: string };

const initialAvailableRatings: Tag[] = []
const initialSelectedRatings: Tag[] = []
export const availableTagsSignal: Signal<Tag[]> = signal(initialAvailableRatings);
export const selectedTagsSignal: Signal<Tag[]> = signal(initialSelectedRatings);
export function initializeTagSignals() {
    availableTagsSignal.value = initialAvailableRatings
    selectedTagsSignal.value = initialSelectedRatings
}
// initializeTagSignals()
// availableTagsSignal.value = [{ id: "123", name: "Tag2" }, { id: "124", name: "Tag1" }]