import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Main";
// Pages
import TagSubmit from "./pages/TagSubmit";
import ProjectConfigurator from "./pages/ProjectConfiguration";
import Stats from "./pages/Stats";
import Maps from "./pages/Maps/Maps";
import InitTagsIndexedDbStore from "./hooks/tagsDb";
InitTagsIndexedDbStore()

// import "./App.css"; to be deleted

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "/", element: <TagSubmit /> },
      { path: "/submitter", element: <TagSubmit /> },
      { path: "/configurator", element: <ProjectConfigurator /> },
      { path: "/stats", element: <Stats /> },
      { path: "/maps", element: <Maps /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
