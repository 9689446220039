import { signal, batch, computed, effect, Signal, ReadonlySignal } from "@preact/signals-react";


export const statsLineItemSignal: Signal<{ tags: string[]; value: number }> = signal({ tags: [], value: 0 });

type Stats = { [key: string]: { sum: number, count: number, avg: number } }
let initialStats: Stats = {}
export const statsSignal: Signal<Stats> = signal(initialStats)

export function initializeStatsSignal() {
    statsSignal.value = initialStats
}


effect(() => {
    let statsSignalNoTrigger = {...statsSignal.peek()}
    const tags = statsLineItemSignal.value.tags.map(tag => {
        if (!statsSignalNoTrigger[tag]) {
            statsSignalNoTrigger[tag] = { sum: 0, count: 0, avg: 0 }
        }
        const sum: number = statsSignalNoTrigger[tag]['sum'] + statsLineItemSignal.value.value
        const count: number = statsSignalNoTrigger[tag]['count'] + 1
        const avg: number = sum / count
        statsSignalNoTrigger[tag] = { sum: sum, count: count, avg: avg }
    })
    statsSignal.value = statsSignalNoTrigger
    // console.log(statsSignal.value)
})
