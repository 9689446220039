import { toast } from 'react-toastify';

import SubmitButton from "../../components/ui/buttons/SubmitButton";
import MainHeader from "../../components/ui/headers/MainHeader";

import SelectedTags from "./components/SelectedTags";
import AvailableTags from "./components/AvailableTags";
import RatingSelect from "./components/RatingSelect";

import { selectedRatingsValueSignal } from "../../signals/ratingSignals";
import { selectedTagsSignal } from "../../signals/tagsSignals";
import { statsLineItemSignal } from "../../signals/statsSignals";

export default function TagSubmit() {
  // Should come from Tag / Rating liberary
  type Tag = { id: string; name: string };
  type Rating = { name: string; value: number };

  // const availableTags: Tag[] = tagsSignal.value;
  function handleSubmit() {
    let submittable = true
    
    if (!selectedTagsSignal.value.length) {
      toast.error("No tag selected.", { autoClose: 2000 })
      submittable = false
    }

    if (selectedRatingsValueSignal.value == undefined) {
      toast.error("No rating value selected.", { autoClose: 2000 })
      submittable = false
    }


    if (submittable) {
      statsLineItemSignal.value = { tags: selectedTagsSignal.value.map(tag => tag.name), value: Number(selectedRatingsValueSignal.value) }
      toast.info("Submitted: " + statsLineItemSignal.value.tags + " " + selectedRatingsValueSignal.value, { autoClose: 2000 })
    }

  }

  return (
    <div className="mt-20 space-y-6">
      <MainHeader headerName="Tag Submit" />
      <AvailableTags></AvailableTags>
      <SelectedTags></SelectedTags>
      <RatingSelect></RatingSelect>
      <div className="flex flex-col justify-center items-center">
        <SubmitButton value="Submit Tags" onClick={handleSubmit}></SubmitButton>
      </div>
    </div>
  );
}
