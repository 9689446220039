import { availableRatingsSignal } from '../signals/ratingSignals';
import { statsSignal } from '../signals/statsSignals';
import { availableTagsSignal } from '../signals/tagsSignals';
import { effect } from '@preact/signals-react';

const InitTagsIndexedDbStore = function () {
    let db: any
    const request = indexedDB.open('tagit', 1);

    request.onsuccess = function (event: any) {
        console.log('onsuccess')
        db = event.target.result;
        const configStore = db.transaction('config', 'readwrite').objectStore('config')
        configStore.get('availableTags').onsuccess = function (event: any) {
            availableTagsSignal.value = event.target.result;
        };
        configStore.get('availableRatings').onsuccess = function (event: any) {
            availableRatingsSignal.value = event.target.result;
        };
        configStore.get('stats').onsuccess = function (event: any) {
            statsSignal.value = event.target.result;
        };
    };

    request.onupgradeneeded = function (event: any) {
        console.log('onupgradeneeded')
        db = event.target.result;
        const configObjStore = db.createObjectStore('config', { keypath: 'id', autoIncrement: true })

        configObjStore.transaction.oncomplete = function (event: any) {
            const configStore = db.transaction('config', 'readwrite').objectStore('config')
            configStore.put(availableTagsSignal.value, 'availableTags')
            configStore.put(availableRatingsSignal.value, 'availableRatings')
            configStore.put(statsSignal.value, 'stats')
        }

    };

    effect(() => {
        console.log(availableTagsSignal.value)
        if (!db) {
            return
        }
        const tagsStore = db.transaction('config', 'readwrite').objectStore('config')
        tagsStore.put(availableTagsSignal.value, 'availableTags')

    })

    effect(() => {
        console.log(availableRatingsSignal.value)
        if (!db) {
            return
        }
        const tagsStore = db.transaction('config', 'readwrite').objectStore('config')
        tagsStore.put(availableRatingsSignal.value, 'availableRatings')

    })

    effect(() => {
        console.log(statsSignal.value)
        if (!db) {
            return
        }
        const tagsStore = db.transaction('config', 'readwrite').objectStore('config')
        tagsStore.put(statsSignal.value, 'stats')

    })

};

export default InitTagsIndexedDbStore