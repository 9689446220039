import { availableRatingsSignal } from "../../../signals/ratingSignals";
import { selectedRatingsValueSignal } from "../../../signals/ratingSignals";

type Rating = { name: string; value: number };

export default function RatingSelect(props: any) {


  function handleCheckedRadioButton(event: any) {
    selectedRatingsValueSignal.value = event.target.value
    console.log('tsestse ' + event.target.value)
  }

  function RatingSelect() {
    if (availableRatingsSignal.value.length > 0) {
      selectedRatingsValueSignal.value = availableRatingsSignal.value[0].value
      return <select id="valueSelect" onChange={handleCheckedRadioButton} className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10 text-center">
        {availableRatingsSignal.value.map((option) => (
          <option key={'option-' + option.name} value={option.value}>{option.name}</option>
        ))}
      </select>
    }
    return <div className="flex justify-center items-center"><p className="text-xl text-red-500 italic">No ratings configured.</p></div>
  }

  return (
    <div key="test" className="flex flex-col">
      <div className="flex flex-row justify-center" >
        <RatingSelect />
      </div>
    </div>
  );
}
