export default function SubmitButton(props: any) {  
  return (
    <input
      type="button"
      value={props.value}
      onClick={props.onClick}
      className="bg-white hover:bg-gray-100 text-gray-400 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
    />
  );
}
