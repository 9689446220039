import MenuLink from "../buttons/MenuLink";
// Import Menu SVG Icons
import { ReactComponent as HomeIcon } from "../../../assets/svg/home.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/svg/settings.svg";
import { ReactComponent as StatsIcon } from "../../../assets/svg/stats.svg";
import { ReactComponent as MapsIcon } from "../../../assets/svg/maps.svg";

const MainMenu = () => {
  return (
    <div>
    <div className="fixed bottom-5 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium ">
        <div></div>
        <MenuLink to="/submitter">
          <HomeIcon />
        </MenuLink>
        <MenuLink to="/stats">
          <StatsIcon />
        </MenuLink>
        {/* <MenuLink to="/maps">
          <MapsIcon />
        </MenuLink> */}
        <MenuLink to="/configurator">
          <SettingsIcon />
        </MenuLink>
        <div></div>
      </div>
    </div>
    {/* <div className="fixed bottom-0 left-0 z-50 grid max-w-lg h-20 grid-cols-5 mx-auto"> <p></p></div> */}
    <div className="fixed bottom-0 left-0 z-50 w-full h-5 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600"></div>

    </div>
  );
};

export default MainMenu;
