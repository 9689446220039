import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import type { ChartData, ChartOptions } from "chart.js";

import MainHeader from "../../components/ui/headers/MainHeader";

import { statsSignal } from "../../signals/statsSignals";

import useBarChart from "./hooks/useBarChart";

type aggregates = {
  sum: number;
  count: number;
  avg: number;
}

const Stats = () => {

  const statsLabels: string[] = Object.keys(statsSignal.value)
  const aggregates: aggregates[] = Object.values(statsSignal.value)
  const sums: number[] = aggregates.map(a => a.sum)
  const counts: number[] = aggregates.map(a => a.count)
  const averages: number[] = aggregates.map(a => a.avg)
  const statsAggregated: number[][] = [sums, counts, averages]

  const [options, data]: [ChartOptions, ChartData<"bar">] = useBarChart(
    statsLabels,
    statsAggregated
  );

  return (
    <div className="mt-20 space-y-20">
      <MainHeader headerName="Statistics" />
      <Bar options={options} data={data} height="500" width="400" />
    </div>
  );
};

export default Stats;
