import { useState } from "react";
import { Base64 } from "js-base64";

import TagButton from "../../../components/ui/buttons/TagButton";
import TextInput from "../../../components/forms/TextInput";
import SubmitButton from "../../../components/ui/buttons/SubmitButton";
import { availableTagsSignal, selectedTagsSignal } from "../../../signals/tagsSignals";

type Tag = { id: string; name: string };

//toDo: import types from repository library e.g. Tag
const TagConfiguration = () => {
  const [tagInputFieldValue, setTagInputFieldValue] = useState("");

  function handleTagInputChange(tagName: string) {
    setTagInputFieldValue(tagName);
  }

  function handleAddTag(tagName: string): void | string {
    const tagId = Base64.encode(tagName);
    const tag: Tag = { id: tagId, name: tagName.trim() };

    if (availableTagsSignal.value.find((tag) => tag.id === tagId)) {
      return "Tag already exists";
    }

    if (tagName !== "") {
      availableTagsSignal.value = [...availableTagsSignal.value, tag]
      setTagInputFieldValue("");
    }
  }

  function handleDeleteTag(tagId: any): void {
    availableTagsSignal.value = availableTagsSignal.value.filter((tag) => tag.id !== tagId);
    selectedTagsSignal.value = selectedTagsSignal.value.filter((tag) => tag.id !== tagId);
  }

  return (
    <div className="space-y-8 mt-20">
      <meta name="viewport" content="width=device-width, user-scalable=no"></meta> 
      <div className="space-y-8">
        <TextInput
          placeholder="Enter Tag"
          id="tag-input"
          value={tagInputFieldValue}
          onChange={(e: any) => handleTagInputChange(e.target.value)}
        ></TextInput>
        <div className="flex justify-center items-center">
          <SubmitButton
            value="Add Tag"
            onClick={() => handleAddTag(tagInputFieldValue)}
          ></SubmitButton>
        </div>
      </div>
      <div className="flex flex-wrap gap-3">
        {availableTagsSignal.value.map((tag) => (
          <TagButton
            key={tag.id}
            buttonDisplayName={tag.name}
            onClick={() => handleDeleteTag(tag.id)}
          ></TagButton>
        ))}
      </div>
    </div>
  );
};

export default TagConfiguration;
