import MainHeader from "../../components/ui/headers/MainHeader";
import DisplayMapFC from "./HereMaps";

const Maps = () => {

  return (

    <div>
      {/* <MainHeader headerName="Maps" />
      <h1>Maps</h1>
      <DisplayMapFC></DisplayMapFC> */}
    </div>
  );
};

export default Maps;
