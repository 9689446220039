import MainHeader from "../../components/ui/headers/MainHeader";
import TagConfiguration from "./components/TagConfiguration";
import HorizontalLine from "../../components/ui/typography/HorizontalLine";
import RatingConfiguration from "./components/RatingConfiguration";
import ResetButton from "./components/ResetButton";

const ProjectConfigurator = () => {
  return (
    <div className="space-y-20">
      <MainHeader headerName="Project Configuration" />
      <TagConfiguration></TagConfiguration>
      <HorizontalLine></HorizontalLine>
      <RatingConfiguration></RatingConfiguration>
      <HorizontalLine></HorizontalLine>
      <ResetButton></ResetButton>
    </div>
  );
}

export default ProjectConfigurator