import { Link } from "react-router-dom";

export default function MenuLink(props: any) {
  return (
    <Link
      to={props.to}
      className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
    >
      {props.children}
    </Link>
  );
}
