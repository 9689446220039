import { Outlet } from "react-router-dom";
import MainMenu from "../components/ui/menus/MainMenu";

export default function Main() {
  return (
    <div className="flex justify-center overflow-y-auto visible">
      <div className="mx-10 mb-40 break-words w-full justify-center">
        <Outlet />
      </div>
      <div className="sticky bottom-0 z-50">
        <MainMenu />
      </div>
    </div>
  );
}
