import { initializeAvailableRatingsSignal } from "../../../signals/ratingSignals";
import { initializeStatsSignal } from "../../../signals/statsSignals";
import { initializeTagSignals } from "../../../signals/tagsSignals";

import SubmitButton from "../../../components/ui/buttons/SubmitButton";

const ResetButton = () => {

  function handleReset(): void {
    initializeAvailableRatingsSignal()
    initializeStatsSignal()
    initializeTagSignals()
  }

  return (
    <div className="space-y-8 mt-20">
      <div className="space-y-8">
          <div className="flex justify-center items-center">
          <h1 className="text-xl font-bold text-red-500">Caution</h1>

          </div>
          <div className="flex justify-center items-center">
          <p className="text-center">The reset button deletes all data including all tags, ratings and statistics.</p>
          </div>
        <div className="flex justify-center items-center">
          <SubmitButton
            value="Reset All Data"
            onClick={() => handleReset()}
          ></SubmitButton>
        </div>
      </div>

    </div>
  );
};

export default ResetButton;
