import { availableTagsSignal, selectedTagsSignal } from "../../../signals/tagsSignals";
import { computed } from "@preact/signals-react";
import TagButton from "../../../components/ui/buttons/TagButton";
type Tag = { id: string; name: string };

export default function AvailableTags() {
  // const [selectedTagsSignal, availableTagsSignal] = useTagSignals()
  const addTagToSelectedTags = (tag: Tag) => {
    selectedTagsSignal.value = [...selectedTagsSignal.value, tag];
  };

  const unsSelectedTagsSignal = computed(() => availableTagsSignal.value.filter(x => !selectedTagsSignal.value.includes(x)));

  return (
    <div>
      <div className="text-gray-400">
        <p>
          <i>Available Tags</i>
        </p>
      </div>
      <div id="available-tags" className="flex flex-wrap gap-3 border-2 p-8">
        {unsSelectedTagsSignal.value.map((tag: Tag) => {
          return (
            <TagButton
              key={tag.id}
              id={tag.id}
              buttonDisplayName={tag.name}
              onClick={() => addTagToSelectedTags(tag)}
            ></TagButton>
          );
        })}
      </div>
    </div>
  );

  // function handleAddSelectedTag(tag: any) {
  //   // testSignal.value = [...testSignal.value, tag];
  //   testSignal.value = [tagButton,tagButton,tagButton];
  //   console.log(testSignal.value);
  // }

  // const Counter = () => {
  //   const tagButton = (
  //     <TagButton
  //       id="1"
  //       buttonDisplayName={"tag"}
  //       // onClick={() => handleAddSelectedTag(tagButton)}
  //     ></TagButton>
  //   );
  //   const addTag = () => {
  //     tagsSignal.value = [...tagsSignal.value, tagButton];
  //     console.log(tagsSignal.value)
  //   };

  //   //effect(() => console.log(count.value));

  //   return (
  //     <div>
  //       <button
  //         onClick={() => {
  //           addTag();
  //         }}
  //       >TTT
  //       </button>
  //       {tagsSignal.value.map((tag: any) => (
  //         <div>{tag}</div>
  //       ))}
  //     </div>
  //   );
  // };

  //   return (
  //     <div id="webcomp-counter">
  //       <Counter />
  //     </div>
  //   );

  // return (<div>{testSignal.value}</div>);
  /** 
  return (
    <div>
      <div className="text-gray-400">
        <p>
          <i>Available Tags</i>
        </p>
      </div>
      <div id="available-tags" className="flex flex-wrap gap-3 border-2 p-8">
        {props.availableTags.map((tag: any) => (
          <TagButton
            id={tag.id}
            buttonDisplayName={tag.name}
            onClick={() => props.handleAddSelectedTag(tag)}
          ></TagButton>
        ))}
      </div>
    </div>
  );
  */
}
