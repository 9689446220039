const MainHeader = (props: any) => {
  return (
    <div className="fixed w-full items-center left-0 top-0 z-50 h-10 bg-gray-100 text-gray-400 p-3">
      <p>
        <b>{props.headerName}</b>
      </p>
    </div>
  );
};

export default MainHeader;
