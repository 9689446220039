import TagButton from "../../../components/ui/buttons/TagButton";
import { selectedTagsSignal } from "../../../signals/tagsSignals";
type Tag = { id: string; name: string };

export default function SelectedTags() {
  const removeTagFromSelectedTags = (tag: Tag) => {
    selectedTagsSignal.value = selectedTagsSignal.value.filter(
      (t: Tag) => tag.id !== t.id
    );
  };
  return (
    <div>
      <div className="text-gray-400">
        <p>
          <i>Selected Tags</i>
        </p>
      </div>
      <div id="selected-tags" className="flex flex-wrap gap-3 border-2 p-8">
        {selectedTagsSignal.value.map((tag: any) => {
          return (
            <TagButton
              key={tag.id}
              id={tag.id}
              buttonDisplayName={tag.name}
              onClick={() => removeTagFromSelectedTags(tag)}
            ></TagButton>
          );
        })}
      </div>
    </div>
  );
}
