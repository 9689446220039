import type { ChartData, ChartOptions } from "chart.js";

const useBarChart = (
  labels: string[],
  [sums, counts, averages]: number[][]
): [ChartOptions, ChartData<"bar">] => {
  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Tag Aggregates",
      },
    },
  };

  const data: ChartData<"bar"> = {
    labels: labels,
    datasets: [
      {
        label: "sum",
        backgroundColor: "rgb(147 197 253)",
        borderRadius: 6,
        data: sums,
      },
      {
        label: "count",
        backgroundColor: "rgb(175, 147, 253)",
        borderRadius: 6,
        data: counts,
      },
      {
        label: "average",
        backgroundColor: "rgb(147 ,253, 203)",
        borderRadius: 6,
        data: averages,
      },
    ],
  };

  return [options, data];
};

export default useBarChart;
